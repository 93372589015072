/* Toggle between showing and hiding the navigation menu links when the user clicks on the hamburger menu / bar icon */
function toggleMobileNav() {
  let e = document.getElementsByClassName('mobile-menu-toggler')[0];
  e.classList.toggle("open"); 
  let x = document.getElementById("menu");
  if (e.classList.contains('open')) {
    document.getElementsByTagName('body')[0].classList.add('unscrollable-in-mobile');
    x.classList.add('open');
    document.getElementsByClassName('mobile-menu-toggler')[0].innerHTML = '✕';
  } else {
    document.getElementsByTagName('body')[0].classList.remove('unscrollable-in-mobile');
    x.classList.remove('open');
    document.getElementsByClassName('mobile-menu-toggler')[0].innerHTML = '☰';    
  }
}

const mediaQueryList = window.matchMedia('(min-width: 768px)');

function screenTest(e) {
  if (e.matches) {
    pruneDropdowns();
    document.getElementById('menu').classList.add('open');
  } else {
    let e = document.getElementsByClassName('mobile-menu-toggler')[0];
    if (e.classList.contains('open') === false) {
      document.getElementById('menu').classList.remove('open');      
    }
  }
}

window.addEventListener('load', function () {
  if (window.sessionStorage.getItem('visited') == null) {
    for (let i of document.getElementsByClassName("startAnimation")) {
      i.classList.add("animated");                     // todo: figure out how to add class toggling arbitrary animation
      i.classList.add("animatedFadeInUp");
      i.classList.add("fadeInUp");
    }
    window.sessionStorage.setItem('visited',true);	
  }
}, false);

document.getElementsByClassName('mobile-menu-toggler')[0].addEventListener("click", toggleMobileNav );
mediaQueryList.addListener(screenTest);

function pruneDropdowns() {
  let firstOpen = false;
  for (let elem of document.getElementsByClassName('dropdown')) {
    if (elem.open) {
      if (!firstOpen) {
        firstOpen = true;
        continue;
      }
      elem.open = false;
    }
  }
}
      
function closeDropdowns(e) {
  if (window.getComputedStyle(document.getElementById('menu_mobile')).display === 'block') {return}
  if (document.getElementById('menu_mobile').style.display == 'none') { return; }
  if (!e.target.open) { return; }
  for (let elem of document.getElementsByClassName('dropdown')) {
    if (elem !== e.target && elem.open) {
      elem.open = false;
    }
  }
}

for (let elem of document.getElementsByClassName('dropdown')) {
  elem.addEventListener("toggle", function(e) {closeDropdowns(e)} );
}
