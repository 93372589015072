class ContactInfo {  
  constructor() {
    // console.log('Contact info module load');
    const context = this;
    window.addEventListener('load', function () {
      document.getElementById('cont-inf').innerHTML = context.getContInfo();
      for (let elem of document.getElementsByClassName('cont-mail')) {
        elem.href = context.getAddr(true);
      }
    }, false);
  }
  
  getContInfo() {
    return '<a href="' + this.getAddr(true) + '">' + this.getAddr() + '</a>';
  }

  getAddr(x=false) {
    const a = 'ymuxcå';
    const y1 = 'aqqccm';
    const y2 = 'edåwwå';
    const y3 = 'bhavmzqz';
    let out = '';
    if (x === true) { out += this.decipher(a) + ':'; }
    out += this.decipher(y1) + '.' + this.decipher(y2) + '-' + this.decipher(y3) + '@' + 'spekuloi.net';
    return out;
  }

  decipher(sourceString) {
    if (!(typeof sourceString === 'string' || sourceString instanceof String)) {
      return;
    }
    const a = {
      'a': 'r',
      'b': 's',
      'c': 't',
      'd': 'u',
      'e': 'v',
      'f': 'w',
      'g': 'x',
      'h': 'y',
      'i': 'z',
      'j': 'å',
      'k': 'ä',
      'l': 'ö',
      'm': 'a',
      'n': 'b',
      'o': 'c',
      'p': 'd',
      'q': 'e',
      'r': 'f',
      's': 'g',
      't': 'h',
      'u': 'i',
      'v': 'j',
      'w': 'k',
      'x': 'l',
      'y': 'm',
      'z': 'n',
      'å': 'o',
      'ä': 'p',
      'ö': 'q',
    };
    let target = "";
    for (const l of sourceString) {
      target += a[l];
    }
    return target;
  }
}

export default ContactInfo;
