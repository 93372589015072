const pendingDelay = 500;


// console.log('info box script loaded');

/*
const callback = (mutationList, observer) => {
  for (const mutation of mutationList) {
    console.log(mutation);
    if (mutation.type === 'attributes' &&
        mutation.attributeName === 'class' &&
        mutation.target.tagName === 'DIV' &&
        mutation.target.classList.contains('image-box__image')
       ) {
      console.log('Image div classlist changed');
      if (mutation.target.classList.contains('selected')) {
        mutation.target.style.opacity = 1.0;
      }
      else {
      }
    }
  }
};
*/

function handleSelected(element, transitionDelay) {
  // console.log('delay',transitionDelay);
  setTimeout(function() {
    element.classList.remove('visible');
  }, parseInt(transitionDelay));
}

function handlePending(element, transitionDelay) {
  // console.log('delay (pending)',transitionDelay);  
  setTimeout(function() {
    element.classList.remove('pending');
    element.classList.add('selected','visible');
    handleSelected(element, transitionDelay);
  }, 500);
}

const boxes = document.querySelectorAll('.three-box-ad__box.shifter');
// console.log(boxes);
// const config = { attributes: true, childList: true, subtree: true };
for (const box of boxes) {
  if (box.childNodes.length > 1) {

    /* add visibility change listener to restart rotation as necessary
       (when window loses focus CSS apparently stops firing a transitionend
       event, which stops the rotation. */
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
        // console.log('regained focus');
        for (const childNode of box.childNodes) {
          if (childNode.classList.contains('selected') && !childNode.classList.contains('visible')) {
            // console.log('Restarting rotation');
            childNode.classList.remove('selected');
            let next = childNode.nextSibling;
            if (!next) {
              next = childNode.parentNode.childNodes[0];
            }
            // console.log('Next node', next);
            next.classList.add('pending');
            handlePending(next, transitionDelay);
          }
        }
      }
      if (document.visibilityState === 'hidden') {
        // console.log('lost focus');
      }
    });
    
    const transitionDelay = box.dataset.delay;
    for (const childNode of box.childNodes) {
      // console.log('adding event listener');
      childNode.addEventListener('transitionend', (event) => {
        // console.log('triggered event listener', event);
        if (window.getComputedStyle(event.target).getPropertyValue('opacity') === '0') {
          // console.log('Invisible');
          event.target.classList.remove('selected');
          let next = event.target.nextSibling;
          if (!next) {
            next = event.target.parentNode.childNodes[0];
          }
          // console.log('Next node', next);
          next.classList.add('pending');
          handlePending(next, transitionDelay);
        }
      });
      // console.log(childNode);
      // console.log(childNode.classList);
      if (childNode.classList.contains('selected')) {
        handleSelected(childNode, transitionDelay);
      }
    }
    
    /*
    const observer = new MutationObserver(callback);
    observer.observe(box,config);
    */
  }
}

  // add listeners for class changes
  // force run listener function on first selected (?)
  //
  // listener:
  // if class includes "selected"
  // wait n sec
  // opacity 1 -> 0
  // display: none
  // remove "selected"
  // next sibling: add "selected"
  // if no next sibling: first child: add "selected"
